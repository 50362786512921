import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "m-panel__inner"
};
var _hoisted_2 = {
  class: "d-flex justify-content-between"
};
var _hoisted_3 = {
  class: "title"
};
var _hoisted_4 = {
  class: "content"
};
import { ref, reactive, onMounted, nextTick } from "vue";
import { Pos, Customer } from "@/js/services/baseService";
export default {
  __name: 'SMSSettingPanel',
  props: {
    code: String
  },
  setup: function setup(__props) {
    var props = __props;
    var cData = reactive({
      list: []
    });
    Customer.messageSetting({
      customer_code: props.code
    }).then(function (response) {
      cData.list = response.data.map(function (row) {
        row.topic = row.name;
        row.checked = row.customer_settings.length ? false : true;
        return row;
      });
    });

    var changeSetting = function changeSetting(item, status) {
      Customer.messageSettingSave({
        message_id: item.id,
        value: status ? 1 : 0,
        customer_code: props.code
      }).then(function (response) {
        console.log(response.data);
      });
    };

    return function (_ctx, _cache) {
      var _component_MPanelHeader = _resolveComponent("MPanelHeader");

      var _component_BaseSpacer = _resolveComponent("BaseSpacer");

      var _component_BaseDivider = _resolveComponent("BaseDivider");

      var _component_MSwitch = _resolveComponent("MSwitch");

      var _component_VList = _resolveComponent("VList");

      var _component_MPanel = _resolveComponent("MPanel");

      return _openBlock(), _createBlock(_component_MPanel, {
        class: "sms-setting-panel"
      }, {
        header: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_MPanelHeader, {
            noLeft: ""
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(" 簡訊設定 ")];
            }),
            _: 1
          })])];
        }),
        default: _withCtx(function () {
          return [_createVNode(_component_BaseSpacer, {
            h: "20px"
          }), _createVNode(_component_BaseDivider, {
            color: "var(--color-divider-line)"
          }), _createVNode(_component_VList, {
            solidLine: "",
            inset: "15px"
          }, {
            default: _withCtx(function () {
              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cData.list, function (item, idx) {
                return _openBlock(), _createElementBlock("div", {
                  class: "list-item",
                  key: idx
                }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(item.topic), 1), _createElementVNode("div", _hoisted_4, [_createVNode(_component_MSwitch, {
                  modelValue: item.checked,
                  "onUpdate:modelValue": [function ($event) {
                    return item.checked = $event;
                  }, function ($event) {
                    return changeSetting(item, $event);
                  }],
                  activeBg: "#02A897"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])])])]);
              }), 128))];
            }),
            _: 1
          })];
        }),
        _: 1
      });
    };
  }
};